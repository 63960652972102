import { Link } from "gatsby"
import React, { useContext } from "react"
import Container from "../Container/Container"
import "./hero.css"
import Video from "../../video/hero.mp4"
import { useSettingsQuery } from "../../hooks/useSettingsQuery"
import { useVestigingenQuery } from "../../hooks/useVestigingenQuery"
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faChevronRight,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"
import VestigingContext from "../../store/vestiging-context"
import CtaButton from "../CtaButton/cta"
import G from "../../images/groenhout_g.png"

const Hero = props => {
  const data = useSettingsQuery()
  const v = useVestigingenQuery()
  const ctx = useContext(VestigingContext)

  const homePage = data.allWp.edges[0].node.acfOptionsHomepagina.homepageField

  const selectedArr = v.allWpVestiging.nodes.filter(e => {
    let image =
      e.title === ctx.vestiging
        ? e.vestigingen.homepageHeaderAfbeeldingVestigingSpecifiek.mediaItemUrl
        : null
    return image
  })

  const headerImage =
    (ctx.vestiging !== undefined && selectedArr.length > 0) ||
    (ctx.vestiging !== `undefined` && selectedArr.length > 0)
      ? selectedArr[0].vestigingen.homepageHeaderAfbeeldingVestigingSpecifiek
          .mediaItemUrl
      : homePage.homepaginaAfbeeldingAlgemeen.mediaItemUrl

  const title = homePage.heroTitel
  const ctaTitle = homePage.homepaginaKnoppenTitel
  const ctaArr = homePage.homepaginaKnoppen
  // const desc = props.desc
  const links = ctaArr.map((cta, i) => (
    <CtaButton
      target={cta.knopLink}
      title={cta.knopTitel}
      icon={faArrowRight}
    ></CtaButton>
    // <Link to={cta.knopLink} key={i}><span className="text-white bg-red  block p-4 hover:pr-5 mt-4 flex flex-row justify-between items-center fullBtn"><span className="buttonHoverWhite">{cta.knopTitel}</span> <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></span></Link>
  ))

  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0 }}
      className="relative h-screen lg:h-full"
    >
      <div className="container-placer">
        <div
          className="w-full bg-blue-light absolute top-0 z-10"
          style={{ height: `75vh` }}
        ></div>
        <div className="embed-container">
          <iframe
            id="videoBG"
            src="https://player.vimeo.com/video/961060937?background=1&autoplay=1&loop=1&muted=1"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        </div>
      </div>
      <div className="w-full absolute top-0 h-full lg:h-full block z-20 pt-64 lg:pt-0">
        <Container styles="h-full flex flex-col lg:flex-row items-center justify-between px-5 lg:px-0">
          <motion.div
            initial={{ translateY: -100, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="w-full lg:w-7/12 mx-auto lg:mx-0"
          >
            <h1 className="text-light text-5xl drop-shadow-lg">{title}</h1>
          </motion.div>
          <motion.div
            initial={{ translateY: -100, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.65 }}
            className="w-full lg:w-5/12 mx-auto lg:mx-0 mt-4 lg:mt-32 md:shadow-md"
          >
            <div className="md:p-10 p-8 bg-white grid gap-6">{links}</div>
          </motion.div>
          <img
            src={G}
            alt="G-logo"
            className="absolute bottom-0 hidden lg:block slg:-bottom-16 m-0 left-0 h-56"
          />
        </Container>
      </div>
    </motion.div>
  )
}

export default Hero
